import { GetServerSidePropsContext } from "next";
import { getSession } from "next-auth/client";

import { Address } from "components/newUI/FindAddress";

import {
  ApiCancellationSummary,
  ApiCancelSubscriptionData,
  ApiLogin,
  ApiPostCodeAutocompleteResult,
  ApiResponse,
} from "../types/api";
import endPoints from "./endpoints";
import fetcher, { swrFetcher } from "./fetcher";

type Option = {
  method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  payload?: unknown;
  withAuth?: boolean;
  contentType?: string;
  context?: GetServerSidePropsContext;
};

interface RequestOption extends RequestInit {
  headers: HeadersInit & {
    authorization?: string;
  };
}

const getToken = async (context?: GetServerSidePropsContext) => {
  const session = await getSession(context);
  return session?.accessToken;
};

export const makeOptions = async ({
  method = "GET",
  payload,
  contentType = "application/json",
  withAuth = true,
  context,
}: Option): Promise<RequestOption> => {
  const options: RequestOption = {
    method,
    headers: {
      "Content-Type": contentType,
      Accept: "application/json",
    },
  };

  const token = await getToken(context);

  if (token && withAuth) {
    options.headers.authorization = `JWT ${token}`;
  }

  if (payload) {
    options.body = JSON.stringify(payload);
  }

  return options;
};

export const loginWithCredentialsApi = async (
  payload: Record<string, string>
): Promise<ApiResponse<ApiLogin>> =>
  fetcher<ApiLogin>(
    endPoints.login(),
    await makeOptions({
      method: "POST",
      payload,
      withAuth: false,
    })
  );

export const cancelSubscription = async (
  payload: ApiCancelSubscriptionData
): Promise<ApiResponse<ApiCancellationSummary>> =>
  fetcher<ApiCancellationSummary>(
    endPoints.cancelSubscription(),
    await makeOptions({
      method: "POST",
      payload,
      withAuth: true,
    })
  );

export const createVerificationSession = async (): Promise<
  ApiResponse<string>
> =>
  fetcher<string>(
    endPoints.createVerificationSession(),
    await makeOptions({
      method: "POST",
      withAuth: true,
    })
  );

export const getKustomerToken = async (): Promise<ApiResponse<string>> =>
  fetcher<string>(
    endPoints.getKustomerToken(),
    await makeOptions({
      method: "GET",
      withAuth: true,
    })
  );

export const confirmVerificationSession = async (
  attempt: number
): Promise<ApiResponse<string>> =>
  fetcher<string>(
    endPoints.confirmVerificationSession(),
    await makeOptions({
      method: "POST",
      payload: { attempt },
      withAuth: true,
    })
  );

export const swrGetCancellationSummary = async (
  expectedCancellationDate: Date,
  withoutCollectionFee: boolean,
  chargeNewFlexiblePlan?: boolean
): Promise<ApiCancellationSummary> =>
  swrFetcher<ApiCancellationSummary>(
    endPoints.getCancellationSummary(
      expectedCancellationDate,
      withoutCollectionFee,
      chargeNewFlexiblePlan
    ),
    await makeOptions({
      method: "GET",
      withAuth: true,
    })
  );

export const swrGetPostcodeAutocomplete = async (
  query: string
): Promise<Array<ApiPostCodeAutocompleteResult>> => {
  const { result } = await swrFetcher<{
    result: {
      hits: ApiPostCodeAutocompleteResult[];
    };
  }>(
    endPoints.postcodeAutocomplete(query),
    await makeOptions({
      method: "GET",
      withAuth: false,
    })
  );
  return result.hits;
};

export const getUdprn = async (udprn: string): Promise<Address> => {
  const { result } = await swrFetcher<{
    result: {
      line_1: string;
      line_2: string;
      post_town: string;
      postcode: string;
      sub_building_name: string;
      building_name: string;
      building_number: string;
      thoroughfare: string;
      dependant_thoroughfare: string;
      district: string;
      county: string;
    };
  }>(
    endPoints.udprn(udprn),
    await makeOptions({
      method: "GET",
      withAuth: false,
    })
  );
  return {
    postcode: result.postcode,
    address_line_one: result.line_1,
    address_line_two: result.line_2,
    city: result.post_town
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase()),
    flat: result.sub_building_name,
    house_name: result.building_name,
    house_number: result.building_number,
    street: result.thoroughfare,
    street_2: result.dependant_thoroughfare,
    district: result.district,
    county: result.county,
  };
};

export const getUserVariant = async (
  experimentCode: string,
  context?: GetServerSidePropsContext
): Promise<string | null> => {
  const { flow } = await swrFetcher<{
    flow: string | null;
  }>(
    endPoints.getUserVariant(experimentCode),
    await makeOptions({
      method: "GET",
      withAuth: true,
      context,
    })
  );

  return flow;
};
